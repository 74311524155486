import { useSettings } from '@wix/tpa-settings/react';
import { useExperiments } from '@wix/yoshi-flow-editor';
import settingsParams from '../settingsParams';
import { ISection, SectionTypes } from '../types';

export type visibilitySectionBookButtonStyleParams =
  | 'sectionTitleBookButtonVisibility'
  | 'sectionSchedulingBookButtonVisibility'
  | 'sectionContactBookButtonVisibility'
  | 'sectionDescriptionBookButtonVisibility'
  | 'sectionDetailsBookButtonVisibility'
  | 'sectionGalleryBookButtonVisibility'
  | 'sectionPluginBookButtonVisibility'
  | 'sectionPolicyBookButtonVisibility';
export interface SectionCssProperties {
  bookButonVisible: visibilitySectionBookButtonStyleParams;
}

export const sectionSettingsCssProperties: Record<
  SectionTypes,
  SectionCssProperties
> = {
  [SectionTypes.TITLE_TAGLINE]: {
    bookButonVisible: 'sectionTitleBookButtonVisibility',
  },
  [SectionTypes.SCHEDULING]: {
    bookButonVisible: 'sectionSchedulingBookButtonVisibility',
  },
  [SectionTypes.CONTACT]: {
    bookButonVisible: 'sectionContactBookButtonVisibility',
  },
  [SectionTypes.DESCRIPTION]: {
    bookButonVisible: 'sectionDescriptionBookButtonVisibility',
  },
  [SectionTypes.DETAILS]: {
    bookButonVisible: 'sectionDetailsBookButtonVisibility',
  },
  [SectionTypes.GALLERY]: {
    bookButonVisible: 'sectionGalleryBookButtonVisibility',
  },
  [SectionTypes.PLUGIN]: {
    bookButonVisible: 'sectionPluginBookButtonVisibility',
  },
  [SectionTypes.POLICY]: {
    bookButonVisible: 'sectionPolicyBookButtonVisibility',
  },
};

export const useSettingsSections = (): ISection[] => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const isPluginInstallationOnServicePageEnabled = experiments.enabled(
    'specs.bookings.enablePluginInstallationOnServicePage',
  );

  let sections: ISection[] = settings
    .get(settingsParams.sections)
    .filter((section) => section);

  if (sections.map(({ type }) => type).indexOf(SectionTypes.GALLERY) === -1) {
    sections.push({
      type: SectionTypes.GALLERY,
      visible: true,
      bookButton: false,
    });
  }

  if (!isPluginInstallationOnServicePageEnabled) {
    sections = sections.filter(
      (section) => section.type !== SectionTypes.PLUGIN,
    );
  } else {
    if (sections.map(({ type }) => type).indexOf(SectionTypes.PLUGIN) === -1) {
      sections.push({
        type: SectionTypes.PLUGIN,
        visible: true,
        bookButton: false,
      });
    }
  }

  return sections;
};
