import { useSettings } from '@wix/tpa-settings/react';
import { useExperiments } from '@wix/yoshi-flow-editor';
import settingsParams from '../settingsParams';
import { SectionTypes } from '../types';
import { VisibilityKey } from './types';

export function useVisibilityExperiment() {
  const { experiments } = useExperiments();
  const settings = useSettings();
  const isVisibilityStyleParamsEnabled = experiments.enabled(
    'specs.bookings.VisibilityStyleParams',
  );
  return {
    isVisibilityStyleParamsEnabled,
    addVisibilityClass: (
      className: string,
      visibilityClassName: string,
      key?: VisibilityKey,
    ) => {
      return isVisibilityStyleParamsEnabled &&
        (!key || typeof settings.get(settingsParams[key]) !== 'boolean')
        ? className
          ? `${className} ${visibilityClassName}`
          : visibilityClassName
        : className;
    },
    addSectionBookButtonVisibilityClass: (
      className: string,
      visibilityClassName: string,
      sectionType: SectionTypes,
    ) => {
      const section = settings
        .get(settingsParams.sections)
        .find((currSection) => currSection.type === sectionType);
      return isVisibilityStyleParamsEnabled &&
        typeof section?.bookButton !== 'boolean'
        ? className
          ? `${className} ${visibilityClassName}`
          : visibilityClassName
        : className;
    },
  };
}
