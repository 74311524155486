import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { sectionSettingsCssProperties } from '../Settings/useSettingsSections';
import settingsParams, {
  uninitializedVisibilitySettingsTrueValue,
} from '../settingsParams';
import stylesParams from '../stylesParams';
import { ISection, SectionTypes } from '../types';
import { VisibilityKey } from './types';
import { useVisibilityExperiment } from './useVisibilityExperiment';

export function useVisibilityCheck() {
  const settings = useSettings();
  const { isVisibilityStyleParamsEnabled } = useVisibilityExperiment();
  const styles = useStyles();

  const sectionShouldBeVisibleHandler = (
    keyInSectionSettings: keyof ISection,
    sectionType: SectionTypes,
    styleParamCssProperty: keyof typeof stylesParams,
  ) => {
    const section = settings
      .get(settingsParams.sections)
      .find((currSection) => currSection.type === sectionType);
    const settingsValue = section![keyInSectionSettings];
    if (typeof settingsValue === 'boolean') {
      return settingsValue;
    }
    if (isVisibilityStyleParamsEnabled) {
      return styles
        .getStylesForAllBreakpoints()
        .some(({ booleans }) => booleans[styleParamCssProperty]);
    } else {
      // @ts-expect-error
      return settingsValue === uninitializedVisibilitySettingsTrueValue;
    }
  };

  return {
    shouldBeVisible(key: VisibilityKey) {
      const settingsValue = settings.get(settingsParams[key]);
      if (isVisibilityStyleParamsEnabled) {
        return typeof settingsValue === 'boolean'
          ? settingsValue
          : styles
              .getStylesForAllBreakpoints()
              .some(({ booleans }) => booleans[key]);
      } else {
        return typeof settingsValue === 'boolean'
          ? settingsValue
          : settingsValue === uninitializedVisibilitySettingsTrueValue;
      }
    },
    shouldSectionBeVisible(sectionType: SectionTypes) {
      return sectionShouldBeVisibleHandler(
        'visible',
        sectionType,
        sectionSettingsCssProperties[sectionType].bookButonVisible,
      );
    },
    shouldSectionBookButtonBeVisible(sectionType: SectionTypes) {
      
      return sectionShouldBeVisibleHandler(
        'bookButton',
        sectionType,
        sectionSettingsCssProperties[sectionType].bookButonVisible,
      );
    },
  };
}
