import React from 'react';
import { SectionTypes, AlignmentOptions } from '../../types';
import BookButton from '../BookButton/BookButton';
import { st, classes } from './Section.st.css';
import { Divider } from 'wix-ui-tpa/cssVars';
import { useGlobalAlignment } from '../useGlobalAlignment';
import { useVisibilityExperiment } from '../../hooks/useVisibilityExperiment';
import { sectionSettingsCssProperties } from '../../Settings/useSettingsSections';

interface SectionProps {
  withBookButton?: boolean;
  sectionType: SectionTypes;
  buttonAlignment?: AlignmentOptions;
  childrenAlignment?: AlignmentOptions;
  className?: string;
  availability?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({
  children,
  sectionType,
  withBookButton,
  className,
  buttonAlignment,
  childrenAlignment,
  availability,
  ...rest
}) => {
  const globalAlignment = useGlobalAlignment();
  const { addSectionBookButtonVisibilityClass, addVisibilityClass, isVisibilityStyleParamsEnabled } = useVisibilityExperiment();
  const bookbuttonClassName = addSectionBookButtonVisibilityClass(
    st(
      classes.buttonWrapper,
      {
        alignment: buttonAlignment || globalAlignment,
        spaceBetweenSections: !availability,
      },
      className,
    ),
    classes[sectionSettingsCssProperties[sectionType].bookButonVisible],
    sectionType,
  );

  return (
    <>
      <div
        className={st(classes.root, {
          withBookButton: isVisibilityStyleParamsEnabled
            ? false
            : !!withBookButton,
          alignment: childrenAlignment || globalAlignment,
          extendedSpacing: sectionType === SectionTypes.GALLERY,
        })}
        {...rest}
        key={sectionType}
        data-section-type={sectionType}
      >
        {sectionType !== SectionTypes.DETAILS &&
          sectionType !== SectionTypes.GALLERY && (
            <Divider
              data-hook="section-divider"
              className={
                isVisibilityStyleParamsEnabled
                  ? addVisibilityClass(
                      classes.divider,
                      classes.dividerDisplay,
                    )
                  : classes.divider
              }
            />
          )}
        {children && (
          <div
            data-hook={`children-${sectionType}`}
            className={st(classes.childrenWrapper, {}, className)}
          >
            {children}
          </div>
        )}
        {availability && (
          <div
            className={st(classes.availability, {
              alignment: buttonAlignment || globalAlignment,
            })}
          >
            {availability}
          </div>
        )}
        {withBookButton && (
          <div data-hook="book-button-wrapper" className={bookbuttonClassName}>
            <BookButton
              dataHook={`book-button-${sectionType}`}
              renderedAt={sectionType}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Section;
